<template>
  <div>
    <v-layout wrap justify-center id="cf">
      <ServerError v-if="ServerError" />
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="blue"
        spinner="mini-spinner"
      />
      <v-flex xs12 lg11 pa-sm-4 pa-lg-6>
        <v-layout wrap justify-center>
          <v-flex xs12>
            <v-card class="mx-auto elevation-1 pa-6" align="center" outlined>
              <v-form ref="form">
                <v-layout wrap>
                  <v-flex xs12 sm6 md3 lg3 align-self-center text-left>
                    <span class="nsbold" style="font-size: 23px"
                      >Room Management</span
                    >
                  </v-flex>
                  <v-spacer></v-spacer>
                   <v-flex xs12 sm6 lg3 pb-2 pb-lg-0 align-self-end text-right pr-1 class="nsbold text-left"
              >
              <v-btn 
                color="#766BC0"
                class="rounded-xl white--text hidden-xs-only"
                title="Map Student"
                 @click="$router.push({ path: '/hostelStList' })"
                >Hostel Allotment List</v-btn
              >
               <v-btn 
               block
                color="#766BC0"
                class="rounded-xl white--text hidden-sm-and-up"
                title="Map Student"
                 @click="$router.push({ path: '/hostelStList' })"
                >Hostel Allotment List</v-btn
              >
              </v-flex>
                  <v-flex xs12 sm6 md3 lg3 pb-1 pb-sm-0 align-self-center text-right pr-2>
                     <v-select
                :items="HostelList"
                v-model="hostel"
                label="Hostel"
                item-text="hostelName"
                item-value="_id"
                      class="rounded-xl"
                hide-details
                outlined
                @input="getData()"
                dense
              ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md3 lg2 align-self-center text-right>
                    <v-text-field
                      v-model="keyword"
                      dense
                      class="rounded-xl"
                      label="Search"
                      outlined
                      hide-details
                      clearable
                    ></v-text-field>
                  </v-flex>
                  <v-flex text-right align-self-center >
                    <span class="dot" style="background-color: #5d4fb4"></span
                    >&nbsp;Filled&nbsp;
                    <span class="dot" style="background-color: #b6b0dd"></span
                    >&nbsp;Partial&nbsp; <span class="dot"></span>&nbsp;Empty
                  </v-flex>
                </v-layout>
                <!---------------------FLOOR--------------------------->
                <v-layout wrap>
                  <v-flex xs12 v-for="(item1, i) in roomList" :key="i" py-2>
                    <v-card outlined>
                      <v-layout wrap>
                        <v-flex
                          xs12
                          text-left
                          class="nsregular"
                          style="font-size: 18px"
                          px-2
                          pt-2
                        >
                          <span>
                            {{ item1.FloorName }}
                          </span>
                        </v-flex>
                        <v-flex
                          xs12 sm3 md2
                          v-for="(item2, j) in item1.data"
                          :key="j"
                          pa-1
                        >
                          <v-card
                            :dark="item2.allocatedStatus != 'empty'"
                            :outlined="item2.allocatedStatus == 'empty'"
                            dense
                            :color="
                              item2.allocatedStatus == 'filled'
                                ? '#5d4fb4'
                                : '' || item2.allocatedStatus == 'partial'
                                ? '#b6b0dd'
                                : ''
                            "
                            @click="
                              (curItem = item2),
                                (mapdialogue = true),
                                getRoom(item2._id), hostelMapList()
                            "
                          >
                            <v-layout wrap>
                              <v-flex xs12 class="nsbold">
                                Room No:{{ item2.roomNo }}
                              </v-flex>
                              <v-flex xs12>
                                Capacity: {{ item2.allocatedNumber }}/{{
                                  item2.totalCapacity
                                }}
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
                <!----------------------------------------------------->
              </v-form>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-form ref="form">
      <v-dialog width="700px" v-model="mapdialogue">
        <v-layout wrap>
          <v-flex xs12>
            <v-card outlined width="700px">
              <v-layout wrap pa-2>
                <v-flex xs12 sm10 text-sm-left>
                  <span
                    v-if="curItem != null"
                    class="nsbold"
                    style="font-size: 16px"
                  >
                    Room No:{{ curItem.roomNo }}
                  </span>
                </v-flex>
                <v-flex xs12 sm2 text-sm-right>
                  <span
                    v-if="curItem != null"
                    class="nsbold"
                    style="font-size: 16px"
                  >
                    {{ curItem.floorId.floorName }}
                  </span>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <v-layout wrap>
                <v-flex xs12>
                   <v-tabs
                color="#766BC0"
                grow
                class="nsbold"
                style="font-size: 16px"
              >
                <v-tab>Add Student ({{count}})</v-tab>
                <v-tab>Student List</v-tab>
                <v-tab-item>
                  <v-layout wrap>
                    <v-flex xs12 pa-2>
                      <v-card outlined>
                        <v-layout wrap>
                          <v-flex xs12>
                            <v-autocomplete
                            :items="AdmnNoList"
                              v-model="admissionNo"
                              :item-text="item=>item.admissionNo + '(' + item.name + ')'"
                              item-value="admissionNo"
                              dense
                              @change="stdetails()"
                              label="Admn.No"
                              outlined
                              :rules="[
                                (v) => !!v || 'Admission number is required',
                              ]"
                            ></v-autocomplete>
                          </v-flex>
                          <v-flex xs12>
                            <v-text-field
                              v-model="name"
                              dense
                              disabled
                              label="Name"
                              outlined
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12>
                            <v-text-field
                              v-model="classs"
                              dense
                              disabled
                              label="Class"
                              outlined
                              @change="stdetails()"
                              :rules="[(v) => !!v || 'This field is required']"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12>
                            <v-text-field
                              v-model="school"
                              dense
                              disabled
                              label="School"
                              outlined
                            ></v-text-field>
                          </v-flex>
                          <v-flex
                            xs12
                            md6
                            align-self-center
                            class="nsregular mt-2"
                          >
                            <v-btn
                              style="font-size: 14px; font-weight: 400"
                              color="#766BC0"
                              dark
                              block
                              depressed
                              large
                              @click="add(curItem._id)"
                              >SUBMIT
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-tab-item>
                <v-tab-item>
                  <v-layout wrap>
                    <v-flex xs12 pa-2 v-if="stList.length > 0">
                      <v-card outlined>
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">No.</th>
                                <th class="text-left">Adm.No</th>
                                <th class="text-left">Name</th>
                                <th class="text-left">Class</th>
                                <th class="text-left">Remove</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, i) in stList" :key="i">
                                <td>
                                  <span v-if="page == 1">
                                    {{ i + 1 }}
                                  </span>
                                  <span v-else>
                                    {{ i + 1 + 20 * (page - 1) }}
                                  </span>
                                </td>
                                <td>
                                  {{ item.admissionNo }}
                                </td>
                                <td>
                                 <router-link
                              style="text-decoration: none; color: black"
                              :to="'/studentOutpass?id=' + item.studentid._id"
                            >
                                    {{ item.studentid.name }}
                                  </router-link>
                                </td>
                                <td>
                                  <span v-if="item.studentid">
                                    {{ item.studentid.studentclass.className }}
                                  </span>
                                  <span v-else>NA</span>
                                </td>
                                <td>
                                  <v-icon
                                    small
                                    style="cursor: pointer"
                                    @click="
                                      (dialoge = true), (curId = item._id)
                                    "
                                    >mdi-delete-outline</v-icon
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card>
                    </v-flex>
                    <v-flex v-else xs12 align-self-center text-center pa-2>
                      <span class="nsregular">Currently no student found!</span>
                    </v-flex>
                  </v-layout>
                </v-tab-item>
              </v-tabs>
                </v-flex>
              </v-layout>
             
            </v-card>
          </v-flex>
        </v-layout>
      </v-dialog>
    </v-form>
    <!-- delete -->
    <v-form ref="form">
      <v-dialog width="400px" v-model="dialoge">
        <v-card width="400px">
          <v-toolbar dark color="#766BC0" dense flat class="body-2">
            <v-toolbar-title> Confirm </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pa-4"
            >Are you sure you want to delete this record?
          </v-card-text>
          <v-card-actions class="pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="dialoge = false"
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              class="body-2 font-weight-bold"
              outlined
              @click="deleteCategory()"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
     <v-snackbar
        :timeout="3000"
        :value="true"
        right
        color="red"
        v-model="snackbar"
      > <span style="font-size: 20px"> {{ msg }}</span>
     </v-snackbar>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      count:null,
      page: 1,
      Pagelength: 0,
      dialoge: false,
      mapdialogue: false,
      // dialogue: false,
      //   adddialogue2: false,
      //   hostelName: null,
      //   hostel: null,
      //   myRoom: null,
      curId: null,
      keyword: null,
      name: null,
      classs: null,
      school: null,
      curItem: null,
      showsnackbar: false,
      snackbar: false,
      HostelList: [],
      admissionNo: null,
      AdmnNoList: [],
      hostelMapedList: [],
      hostel: null,
      msg: null,
      appLoading: false,
      ServerError: false,
      stList: [],
      roomList: [],
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        phoneRules: [
          (v) => !!v || "phone is required",
          (v) =>
            (v && v.length <= 10) || "number must be less than 11 characters",
          (v) =>
            (v && v.length >= 10) ||
            "number must be greater than 10 characters",
          (v) => /^\d{10}$/.test(v) || "phone number must be a number",
        ],
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  mounted() {
    this.gethostel();
    // this.getData();
    this.getFloor();
  },
  watch: {
    hostelName() {
      // console.log("received hostelname");
      this.getData();
    },
    keyword() {
      this.getData();
    },
    page() {
      // this.getData();
      this.getData();
    },
    hostel() {
      this.getData();
    },
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/hostelroom/grouped/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          keyword: this.keyword,
          page: this.page,
          limit: 30,
          mainHostelId: this.hostel,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.roomList = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;

          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },

    gethostel() {
      axios({
        method: "get",
        url: "/main/hostel/name/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.HostelList = response.data.data;
            this.hostel = this.HostelList[0]._id;
            this.getData();
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    getFloor() {
      axios({
        method: "get",
        url: "/floor/name/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.floorList = response.data.data;
            this.type = this.floorList[0]._id;
            // console.log(" this.hostelName", this.hostelName);
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    getRoom(myid) {
      axios({
        method: "post",
        url: "/per/room/studentlist",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          // roomId: this.curItem
          roomId: myid,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.stList = response.data.data;
            // console.log(" this.hostelName", this.hostelName);
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    hostelMapList() {
      axios({
        method: "get",
        url: "/roommap/hostelwise/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          hostelId: this.hostel,
          keyword: this.admissionNo
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.AdmnNoList = response.data.data;
            this.count = response.data.totalLength
            // this.hostel = this.HostelList[0]._id;
            // this.getData();
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    stdetails() {
      // if (this.$refs.form.validate()) {
      //   this.user.classid = this.myClass;
      axios({
        method: "post",
        url: "/admissionnumber/studentdetails",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          admissionNo: this.admissionNo,
        },
      })
        .then((response) => {
          if (response.data.status == true) {

            this.name = response.data.data.name;
            this.classs = response.data.data.studentclass.className;
            this.school = response.data.data.school.SchoolName;
  
            this.msg = response.data.msg;
            this.snackbar = true;
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;

            // if (this.snackbar == false) {
            //   this.$router.go();
            // }

            // setTimeout(() => this.$router.go(),3000)
          }

          
        })
        .catch((err) => {
          console.log(err);
        });
    },
    add(item) {
      if (this.$refs.form.validate()) {
        // this.user.classid = this.myClass;
        console.log();
        // this.user.toDate = this.dateOnly + " " + this.user.toDate;
        axios({
          method: "post",
          url: "/hostel/room/map",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            admissionNo: this.admissionNo,
            roomId: item,
          mainHostelId: this.hostel,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              this.msg = response.data.msg;
              this.snackbar = true;

              this.mapdialogue = false;
              this.getData();
              (this.stList = []),
                (this.admissionNo = null),
                (this.name = null),
                (this.classs = null),
                (this.school = null),
                this.$refs.form.resetValidation();
            } else {
              this.msg = response.data.msg;
              this.snackbar = true;
              this.mapdialogue = false;
              this.getData();
              this.$refs.form.resetValidation();
            }

            this.refresh2 = true;
            this.refresh1 = true;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    //delete button
    deleteCategory() {
      axios({
        url: "/maproom/remove",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.delete = false;
          this.dialoge = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            // this.$router.go(-1)
            this.snackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;
            this.getData();

          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    //add button

    // addCategory() {
    //   if (this.$refs.form.validate()) {
    //     axios({
    //       url: "/hostelroom/add",
    //       method: "POST",
    //       headers: {
    //         token: localStorage.getItem("token"),
    //       },
    //       data: {
    //         hostelName: this.hostel,
    //         type: this.type,
    //         floorId: this.floorId,
    //         roomNo: this.roomNo,
    //         totalCapacity: this.totalCapacity
    //       },
    //     })
    //       .then((response) => {
    //         this.adddialogue = false;
    //         this.appLoading = false;
    //         if (response.data.status) {
    //           this.msg = response.data.msg;
    //           this.showsnackbar = true;
    //           this.name = null;
    //           this.RoomNo = null;
    //           this.getData();
    //         } else {
    //           this.msg = response.data.msg;
    //           this.showsnackbar = true;
    //         }

    //         this.$refs.form1.resetValidation();
    //       })
    //       .catch((err) => {
    //         this.appLoading = false;
    //         this.ServerError = true;
    //         console.log(err);
    //       });
    //   }
    // },
    //  addCategory2() {
    //   if (this.$refs.form.validate()) {
    //     axios({
    //       url: "/hostelroom/muliple/add",
    //       method: "POST",
    //       headers: {
    //         token: localStorage.getItem("token"),
    //       },
    //       data: {
    //         hostelName: this.hostel,
    //         type: this.Type1,
    //         totalCapacity: this.totalCapacity,
    //         floorId: this.floorId,
    //         roomFrom: this.rangeFrom,
    //         roomTo: this.rangeTo
    //       },
    //     })
    //       .then((response) => {
    //         this.adddialogue2 = false;
    //         this.appLoading = false;
    //         if (response.data.status) {
    //           this.msg = response.data.msg;
    //           this.showsnackbar = true;
    //           this.hostel = null;
    //           this.Type1 = null;
    //           this.floorId = null;
    //           this.rangeTo = null;
    //           this.rangeFrom = null;
    //           this.getData();
    //         } else {
    //           this.msg = response.data.msg;
    //           this.showsnackbar = true;
    //         }

    //         this.$refs.form1.resetValidation();
    //       })
    //       .catch((err) => {
    //         this.appLoading = false;
    //         this.ServerError = true;
    //         console.log(err);
    //       });
    //   }
    // },
    //edit button
    // editCategory() {
    //   axios({
    //     url: "/hostelroom/edit",
    //     method: "POST",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //     data: {
    //       id: this.curItem._id,
    //       hostelName: this.curItem.hostelName,
    //       type: this.curItem.type,
    //       floorId: this.curItem.floorId,
    //       roomNo: this.curItem.roomNo,
    //     },
    //   })
    //     .then((response) => {
    //       this.editdialogue = false;
    //       this.appLoading = false;
    //       if (response.data.status) {
    //         this.msg = response.data.msg;
    //         // this.$router.go(-1)
    //         this.showsnackbar = true;
    //         this.getData();
    //       } else {
    //         this.msg = response.data.msg;
    //         this.showsnackbar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
  },
};
</script>
<style>
.dot {
  height: 12px;
  width: 12px;
  border: 1px solid black;
  /* background-color: red; */
  border-radius: 50%;
  display: inline-block;
}
</style>